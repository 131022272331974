<template>
<div class="config-station-container">
  <div class="station-data-area" ref="table">
    <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source
        ref="chkTable" :key="dataRefresh" :data="items" height="auto" stripe
        :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',}" row-id="id">
      <vxe-column type="seq" width="60" ></vxe-column>
      <vxe-column field="name" :title="showLang('com.tab.title')" width="200" header-align="center"></vxe-column>
      <vxe-column field="appKey" title="AppKey" width="270" header-align="center"></vxe-column>
      <vxe-column field="appSecret" title="AppSecret" width="270" header-align="center"></vxe-column>
      <vxe-column title="令牌申请情况">
        <template #default="params">
          {{getMessage(params)}}
        </template>
      </vxe-column>
      <vxe-column field="tokenTime" title="令牌申请时间" width="150" header-align="center"></vxe-column>
      <vxe-column field="expireTime" title="令牌过期时间" width="150" header-align="center"></vxe-column>
      <vxe-column width="220" title="操作" fixed="right">
        <template #header>
          <Button v-if='funCodes(1401)' size="small" type="primary" style="margin-right: 5px" @click="handleAdd">{{showLang('com.op.add')}}</Button>
          <Button v-if='funCodes(1400)' size="small" type="primary" style="margin-right: 5px" @click="getList">{{showLang('com.op.refresh')}}</Button>
        </template>
        <template #default="params">
          <Button v-if='funCodes(1402)' size="small" type="primary" style="margin-right: 5px" @click="handleEdit(params)">{{showLang('com.op.edit')}}</Button>
          <Button v-if='funCodes(1403)' size="small" type="error" style="margin-right: 5px" @click="handleDelete(params)">{{showLang('com.op.del')}}</Button>
        </template>
      </vxe-column>
    </vxe-table>
  </div>
  <ModalAccountEdit v-model="showEditModal" :item="editItem" @saved="getList" />
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalAccountEdit from './ModalAccountEdit'
export default {
  name: 'YsAccountIndex',
  components:{
    ModalAccountEdit,
  },
  props: {
  },
  data () {
    return {
      loading: false,
      showEditModal: false,
      count: 0,
      items: [],
      editItem: {isAdd: false, index: -1, data: {}},
      filter: ''
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapGetters('auth', ['showLang', 'funCodes']),
  },
  watch: {
  },
  mounted: function(){
    this.getList();
  },
  methods: {
    getMessage: function(params){
      if(params.row.message.length > 0)return `申请失败：${params.row.message}`;
      if(params.row.accessToken.length > 0)return `申请成功：${params.row.accessToken}`;
      return '等待申请';
    },
    getList: async function(){
      this.loading = true;
      let res = await this.$axios.post(`device/camera/QueryYingshiyun`, {});
      this.loading = false;
      if(res.code !== 0){
        // this.$Message.error(res.data);
        return;
      }
      this.$set(this, "items", res.data);
    },
    handleAdd: function(){
      this.editItem.isAdd = true;
      this.editItem.index = -1;
      this.editItem.data = {};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem.isAdd = false;
      this.editItem.index = params.index;
      this.editItem.data = params.row;
      this.showEditModal = true;
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除操作[${params.row.name}]吗?`,
        onOk: async () => {
          let res = await this.$axios.post(`device/camera/DeleteYingshiyun`, {list: [params.row.id]});
          if(res.code !== 0){
            // this.$Message.error(res.data);
            return;
          }
          this.$Message.info('删除成功');
          this.getList();
        }
      });
    },
  }
}
</script>
<style scoped>
.config-station-container{
  /* padding: 5px;
  padding-right: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
  padding: 10px;
  background-color: white;
  border-radius: 8px;
}
.station-search-area{
  height: 42px;
  flex: none;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  margin-bottom: 5px;
  padding: 0 3px;
  display: flex;
  align-items: center;
}
.station-data-area{
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>